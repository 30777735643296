import { Link, Typography } from '@mui/material'
import * as React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'

const AboutPage = () => {
    return (
        <Layout>
            <Typography variant='h2'>Support</Typography>

            <Typography variant='body1' sx={{ marginTop: 6 }}>
                Please reach out to <Link href="mailto:club@greensgolf.app">club@greensgolf.app</Link> for help.
            </Typography>
            <Typography variant='body1' sx={{ marginTop: 3 }}>
                For data related inquries, please fill out <Link href="https://forms.gle/j5ziBuBVV8xVRyY69">this form.</Link>
            </Typography>
        </Layout>
    )
}

export const Head = () => (
    <SEO title="Support" />
)

export default AboutPage